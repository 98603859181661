import { FooterNavigationProps, HeaderNavigationProps, Route } from '@maverick/entity';
import { GraphQLRequestClient } from '@sitecore-jss/sitecore-jss-nextjs';

export interface SitecoreRoute {
	id: string;
	url: string;
	label: string;
	newTab: boolean;
}

interface SitecoreRawRoute {
	item: {
		url: {
			path: string;
		};
		displayName: string;
		id: string;
		link?: {
			jsonValue: { value: { href: string } };
		};
	};
}

interface HeaderNavigationResponse {
	item: {
		mainItems: {
			value: string;
		};
		hamburgerItems: {
			value: string;
		};
	};
}

interface FooterNavigationResponse {
	item: {
		featuredItems: {
			value: string;
		};
		unfeaturedItems: {
			value: string;
		};
	};
}

export const GetSitecoreHeaderRoutes = async (id: string, language: string): Promise<HeaderNavigationProps> => {
	const headerItemsIds = await RequestHeaderIds(id, language, getHeaderNavigationItemsGraphQL);
	const mainItems = await GetNavigationData(language, headerItemsIds.item.mainItems.value);
	const hamburgerItems = await GetNavigationData(language, headerItemsIds.item.hamburgerItems.value);

	const response: HeaderNavigationProps = {
		mainItems: GetRoutes(mainItems),
		hamburgerItems: GetRoutes(hamburgerItems),
	};

	return response;
};

export const GetSitecoreFooterRoutes = async (id: string, language: string): Promise<FooterNavigationProps> => {
	const footerItemsIds = await RequestFooterIds(id, language, getFooterNavigationItemsGraphQL);
	const featuredItems = await GetNavigationData(language, footerItemsIds.item.featuredItems.value);
	const unfeaturedItems = await GetNavigationData(language, footerItemsIds.item.unfeaturedItems.value);

	const response: FooterNavigationProps = {
		featuredItems: GetRoutes(featuredItems),
		unfeaturedItems: GetRoutes(unfeaturedItems),
	};

	return response;
};

const GetNavigationData = async (language: string, navigationItems: string): Promise<SitecoreRawRoute[]> => {
	const ids = navigationItems.split('|').map((i) => i.replace('{', '').replace('}', '').replaceAll('-', ''));
	const result = await Promise.all(
		ids.map((id) => {
			return RequestPageData(id, language, getPageGraphQL);
		})
	);

	return result;
};

const GetRoutes = (items: SitecoreRawRoute[]): Array<Route> | null => {
	if (items.length === 1 && !items[0].item) return null;
	const routes = items.map((i) => {
		const route: Route = {
			id: i.item.id,
			url: i.item.link ? i.item.link?.jsonValue?.value?.href : i.item.url.path,
			label: i.item.displayName,
			newTab: false,
		};
		return route;
	});

	return routes;
};

const RequestHeaderIds = async (
	dataSource: string,
	language: string,
	gql: string
): Promise<HeaderNavigationResponse> => {
	const gqlClient = new GraphQLRequestClient(String(process.env.GRAPH_QL_ENDPOINT), {
		apiKey: process.env.SITECORE_API_KEY,
	});

	return await gqlClient.request<HeaderNavigationResponse>(gql, {
		datasource: dataSource,
		language,
	});
};

const RequestFooterIds = async (
	dataSource: string,
	language: string,
	gql: string
): Promise<FooterNavigationResponse> => {
	const gqlClient = new GraphQLRequestClient(String(process.env.GRAPH_QL_ENDPOINT), {
		apiKey: process.env.SITECORE_API_KEY,
	});

	return await gqlClient.request<FooterNavigationResponse>(gql, {
		datasource: dataSource,
		language,
	});
};

const RequestPageData = async (dataSource: string, language: string, gql: string): Promise<SitecoreRawRoute> => {
	const gqlClient = new GraphQLRequestClient(String(process.env.GRAPH_QL_ENDPOINT), {
		apiKey: process.env.SITECORE_API_KEY,
	});

	return await gqlClient.request<SitecoreRawRoute>(gql, {
		datasource: dataSource,
		language,
	});
};

const getHeaderNavigationItemsGraphQL = `
query GetHeaderNavigationItems($datasource: String!, $language: String!) {
  item(path: $datasource, language: $language) {
    ... on _Home {
      mainItems {
        value
      }
      hamburgerItems {
        value
      }
    }
  }
}
`;

const getFooterNavigationItemsGraphQL = `
query GetFooterNavigationItems($datasource: String!, $language: String!) {
  item(path: $datasource, language: $language) {
    ... on _Home {
      featuredItems{
      	value
      }
      unfeaturedItems{
        value
      }
    }
  }
}
`;

const getPageGraphQL = `
query GetPage($datasource: String!, $language: String!) {
  item(path: $datasource, language: $language) {
    url {
      path
    }
    displayName
    id
    ... on _RedirectPage {
      link {
        jsonValue
      }
    }
  }
}
`;
