import Link from 'next/link';
import Head from 'next/head';
import { ThemeProvider } from 'styled-components';
const { getPublicUrl } = require('@sitecore-jss/sitecore-jss-nextjs');
import { OutbackTheme } from '@maverick/themes';
import { CmsFooter, FooterMenuItemProps, OutbackHeader } from '@maverick/ui';
import {
	RewardsBlock,
	mockedModalTextsOutback,
	signedUpResponse,
	welcomeBackResponse,
	IDictionary,
	OneTrustModal,
} from '@maverick/cms';
import { Location } from 'components/cms/Location';
import { MenuLink } from 'components/_shared/MenuLink';
import { SocialMediaIcons } from 'components/_shared/SocialMediaIcons/SocialMediaIcons';
import { HomeData } from 'src/services/HomeData.service';
import { MustBeAMisSteak } from './MustBeAMisSteak';
import { NavigationProps, NavigationVariant } from '@maverick/entity';

const publicUrl = getPublicUrl();

type NotFoundProps = { items: NavigationProps; homeData: HomeData | null; dictionary: IDictionary };
export const NotFound = ({ items, homeData, dictionary }: NotFoundProps): JSX.Element => {
	return (
		<ThemeProvider theme={OutbackTheme}>
			<Head>
				<title>Page Not Found</title>
				<link rel='icon' href={`${publicUrl}/favicon.ico`} />
				<link href={`${publicUrl}/css/globals.css`} rel='stylesheet' />
			</Head>
			<OutbackHeader
				linkComponent={Link}
				extra={<Location />}
				items={items}
				img={<img src={homeData?.fields.Logo?.src} alt={homeData?.fields.Logo?.alt} />}
				imgMobile={<img src={homeData?.fields.LogoMobile?.src} alt={homeData?.fields.LogoMobile?.alt} />}
			/>
			<MustBeAMisSteak />
			<RewardsBlock
				firstDescription={mockedModalTextsOutback.firstDescription}
				secondDescription={mockedModalTextsOutback.secondDescription}
				dictionary={dictionary}
				signedUpResponse={signedUpResponse}
				welcomeBackResponse={welcomeBackResponse}
			/>
			{getFooter()}
		</ThemeProvider>
	);

	function getFooter() {
		const menuItems: FooterMenuItemProps[] | undefined = items.featuredItems?.map((item) => {
			return {
				variant: NavigationVariant.Featured,
				link: MenuLink(item.url, item.label, !item.newTab),
			};
		});
		items.unfeaturedItems?.forEach((item) => {
			menuItems?.push({
				variant: NavigationVariant.Unfeatured,
				link: MenuLink(item.url, item.label, !item.newTab),
			});
		});

		return (
			<CmsFooter
				footerMenuItems={menuItems}
				socialMediaIcons={SocialMediaIcons(
					homeData?.fields?.FacebookUrl ?? '',
					homeData?.fields?.InstagramUrl ?? '',
					homeData?.fields?.TwitterUrl ?? ''
				)}
				oneTrustModal={<OneTrustModal />}
			/>
		);
	}
};

export default NotFound;
