import { useRef } from 'react';
import Head from 'next/head';
import {
	Placeholder,
	VisitorIdentification,
	LayoutServiceData,
	LinkField,
	Field,
} from '@sitecore-jss/sitecore-jss-nextjs';
import { ScrollBlock } from '@maverick/ui';
import { GaEvent, Storage } from '@maverick/utils';
import { useUpdateEffect } from '@maverick/hooks';
import { Constants } from '@maverick/cms';
import { Config } from './Config';

const getPageType = (pathname: string): string => {
	if (pathname === '/') return 'Home Page';
	return 'Content Page';
};

interface LayoutProps {
	layoutData: LayoutServiceData;
}

const Layout = ({ layoutData }: LayoutProps): JSX.Element => {
	const { route } = layoutData.sitecore;
	const containerMain = useRef<HTMLDivElement>(null);
	const baseUrl = String(process.env.CMS_URL);
	const pageTitle =
		(route?.fields?.[Constants.ArticlePage.Fields.PageTitle] as Field)?.value ||
		(route?.fields?.[Constants.BasePage.Fields.Title] as Field)?.value ||
		route?.displayName;

	const metaDescription = (route?.fields?.[Constants.BasePage.Fields.MetaDescription] as Field)?.value;

	const canonicalUrlField = route?.fields?.[Constants.BasePage.Fields.CanonicalUrl] as LinkField;
	const canonicalUrl =
		canonicalUrlField?.value?.linktype === 'internal' ? `${baseUrl}${canonicalUrlField?.value?.href}` : '';

	useUpdateEffect(() => {
		const { pathname } = window.location;
		const pageType = getPageType(pathname);

		const prevPageTitle = Storage.Session.Get<string | undefined>('previous-page-title');
		GaEvent.PageView(route?.displayName, prevPageTitle, pageType);
		Storage.Session.Set('previous-page-title', route?.displayName);

		const handleClick = (ev: MouseEvent) => {
			const element = ev.target as HTMLElement;
			const elementType = element.tagName.toLowerCase();
			if (elementType === 'a') {
				const href = element.getAttribute('href');
				if (!href) return;
				const external = href.includes('http');
				if (external) {
					GaEvent.OutboundLinkClick(href);
				} else {
					GaEvent.InternalLinkClick(href);
				}
			}
		};
		containerMain.current?.addEventListener('click', handleClick);

		return () => {
			Storage.Session.Remove('previous-page-title');
			containerMain.current?.removeEventListener('click', handleClick);
		};
	}, [route]);

	return (
		<>
			<Head>
				<title>{pageTitle as string}</title>
				<link rel='icon' href={`${Config.Urls.Cms}/favicon.ico`} />
				<meta name='apple-itunes-app' content='app-id=1035361376'></meta>
				{!!metaDescription && <meta name='description' content={metaDescription as string} />}
				{!!canonicalUrl && <link href={canonicalUrl} rel='canonical' />}
			</Head>
			<VisitorIdentification />
			<ScrollBlock />

			{route && <Placeholder name='bbi-cms-rewards-modal' rendering={route} />}
			{route && <Placeholder name='bbi-cms-header' rendering={route} />}
			<div className='container container-main' ref={containerMain}>
				{route && <Placeholder name='bbi-cms-main' rendering={route} />}
			</div>
			<div className='container'>{route && <Placeholder name='bbi-cms-footer' rendering={route} />}</div>
		</>
	);
};

export default Layout;
