import { NutritionHolder } from './NutritionHolder';
import { Hero, TabNavigation, TabNavigationItemProps, TextAlign } from '@maverick/cms';
import { FC } from 'react';
import styled from 'styled-components';
import Link from 'next/link';

const Container = styled.div`
	position: relative;
`;
const HeroWrapper = styled.div`
	z-index: 0;
`;
const Content = styled.div`
	position: relative;
	z-index: 1;
`;
const Nav = styled.nav`
	width: 100%;
	background: ${({ theme }) => theme.colors.neutral.white};
`;
const Children = styled.div`
	background: #e5c8a2;
	padding: 52px 0;
	color: #262524;

	p {
		font-size: 1.125rem;
		font-family: Roboto;
	}

	h6 {
		font-family: Roboto-Condensed-Bold;
		font-size: 1.125rem;
		font-weight: normal;
	}

	h1 {
		text-transform: uppercase;
		text-align: center;
		color: ${({ theme }) => theme.colors.primary.medium};
		font-size: 2.75rem;
		font-family: BigBloke;
		font-weight: normal;
	}

	h4 {
		max-width: 1075px;
		margin: 32px auto 0;
		text-align: center;
		line-height: 1.12;
		font-weight: normal;
		font-size: 1.125rem;
		font-family: Roboto;
	}

	@media (max-width: 768px) {
		h1 {
			font-size: 2rem;
		}

		h4 {
			font-size: 1rem;
			max-width: 100%;
			margin: 24px 0 0;
		}

		p, h6 {
			font-size: 1rem;
		}
	}
`;
const Cta = styled.div`
	background: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Background/20240715-outback-red-background-with-texture.jpg);
	position: relative;
	z-index: 1;
`;
const ButtonsContainer = styled.div`
	display: flex;
	gap: 20px;
	font-family: Roboto-Condensed-Bold;

	@media (max-width: 768px) {
		flex-direction: column;
		gap: 0px;

		a {
			min-width: 206px;
		}
	}
`;

const CtaContent = styled.div`
	padding: 80px 16px 80px 50%;
	color: ${({ theme }) => theme.colors.neutral.white};
	text-transform: uppercase;
	background: url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Nutrition/nutrition-footer-steak.webp)
			left center no-repeat,
		url(https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Nutrition/badge-nutrition.webp)
			calc(100% + 50px) center no-repeat;

	span {
		font-size: 4rem;
		font-family: BigBloke;

		@media (max-width: 1300px) {
			font-size: 3.6rem;
		}

		@media (max-width: 1000px) {
			font-size: 3.2rem;
		}

		@media (max-width: 768px) {
			font-size: 3rem;
		}
	}

	a {
		margin: 16px 0 0;
		display: inline-block;
		padding: 12px 24px;
		border: 1.5px solid;
		transition: all ease 0.2s;
		text-align: center;

		&:hover {
			color: #000;
			background: #fff;
			border-color: #fff;
		}
	}

	@media (max-width: 768px) {
		padding-left: 16px;
		background-size: 60%, 40%;
		background-position: left top, right bottom;
	}
`;

interface NutritionWrapperProps {
	activeLink: number;
	children?: React.ReactNode;
}

export const NutritionWrapper: FC<NutritionWrapperProps> = ({ activeLink, children }) => {
	const items: TabNavigationItemProps[] = [
		{ path: '/nutrition/smart-dining', text: 'Smart Dining Tips', isActive: activeLink === 0, Link },
		{ path: '/nutrition/gluten-free', text: 'Gluten Free', isActive: activeLink === 1, Link },
		{ path: '/nutrition/kids-live-well', text: 'Kids Live Well', isActive: activeLink === 2, Link },
	];

	return (
		<Container>
			<HeroWrapper>
				<Hero
					bgDesktop={
						'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Nutrition/nutrition-hero.webp'
					}
					bgMobile={
						'https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/Nutrition/nutrition-hero.webp'
					}
					title={<span>Nutrition information</span>}
					button={
						<a
							href='https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/files/obs-full-nutrition-information.pdf'
							target='_blank'
						>
							Download our nutrition information
						</a>
					}
					overlay={false}
					textBackgroundContrast={false}
					storeBadges={false}
					textAlign={TextAlign.Center}
				/>
			</HeroWrapper>

			<Content>
				<Nav>
					<TabNavigation items={items} currentPage={items[activeLink]?.path || ''} />
				</Nav>

				<Children>
					<NutritionHolder>{children}</NutritionHolder>
				</Children>
			</Content>

			<Cta>
				<CtaContent>
					<span>Download our nutritional information</span>
					<ButtonsContainer>
						<div>
							<a
								href='https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/files/obs-full-nutrition-information.pdf'
								target='_blank'
							>
								Download PDF now
							</a>
						</div>
						<div>
							<a
								href='https://edge.sitecorecloud.io/osi-60501bcc/media/Project/BBI/outback/static-pages/Obs-Full-Allergens-Information.pdf'
								target='_blank'
							>
								Download Allergens pdf
							</a>
						</div>
					</ButtonsContainer>
				</CtaContent>
			</Cta>
		</Container>
	);
};
